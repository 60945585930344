import Main from "../pages/main/main.component";
import {MainDiv} from "./App.styles";

const App = () => {
  return (
    <MainDiv className="App">
      <Main/>
    </MainDiv>
  );
}

export default App;
