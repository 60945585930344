import React from "react";
import {AppBar, Button, Toolbar, Typography} from "@mui/material";

export default function MenuBar() {
  return (
    <AppBar position="static" color='transparent' elevation={0}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          My Mercatus 24
        </Typography>
        {/*<Button color="inherit">Projekty</Button>*/}
        {/*<Button color="inherit">Spolupráce</Button>*/}
        {/*<Button color="inherit">Kontakt</Button>*/}
      </Toolbar>
    </AppBar>
  );
}