import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {SSIOLogo} from "./projects.styles";

export default function Projects() {
  return (
    <Container sx={{mt: 8}}>
      <Typography variant='h5' sx={{mb: 4}}>
        Projekty
      </Typography>

      <Grid container
            spacing={{xs: 2, md: 4}}
            alignItems="center"
            justifyContent="center"
      >
        <Grid container item xs={12} spacing={{xs: 2, md: 4}}>
          <Grid item xs={12} sm={8} md={4}>
            <Box sx={{
              boxShadow: 10,
              borderRadius: 4,
              p: 4
            }}
            >
              <SSIOLogo/>
              <Typography>
                Webová služba umožňující efektivní řízení oddělení nebo celý společností. Unikátní přístup k práci se
                statusem projektů dává odpovědným manažerům a sponzorům klíčové informace a umožňuje přijímat klíčová
                rozhodnuté ve správnou chvíli.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={4}>

          </Grid>
          <Grid item xs={12} sm={8} md={4}>
          </Grid>
        </Grid>
      </Grid>
    </Container>

  )
}