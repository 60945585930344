import React from "react";
import Footer from "../../components/footer/footer.component";
import {Container} from "@mui/material";
import MenuBar from "../../components/menu-bar/menu-bar.component";
import Headline from "../../components/headline/headline.component";
import Projects from "../../components/projects/projects.component";
import Cooperation from "../../components/cooperation/cooperation.component";
import Contact from "../../components/contact/contact.component";

export default function Main() {

  return (
    <Container maxWidth="lg" sx={{bgcolor: "white", height: '100vh', overflowY: "auto"}}>
      <MenuBar/>
      <Headline/>
      <Projects/>
      <Cooperation/>
      <Contact/>
      <Footer/>
    </Container>
  );
}

