import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';

export default function Cooperation() {
  return (
    <Container sx={{mt: 8}}>
      <Typography variant='h5' sx={{mb: 4}}>
        S čím můžeme pomoct?
      </Typography>

      <Grid container
            spacing={{xs: 2, md: 4}}
            alignItems="center"
            justifyContent="center"
      >
        <Grid container item xs={12}>
          <Grid xs={12} sm={6} md={4}>
            <Box sx={{
              p: 2
            }}
            >
              <GroupsOutlinedIcon
                fontSize='large'
                color='secondary'
              />
              <Typography variant='h5'>
                Společný projekt
              </Typography>
              <Typography variant='body2' mt={2}>
                Máte zajímavý nápad, ale hodila by se pomoc v realizaci? Ozvěte se a rádi pomůžeme s kompletní dodávkou.
                Pokud se staneme partnery, můžou jít realizační náklady za námi.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Box sx={{
              p: 2
            }}
            >
              <DescriptionOutlinedIcon
                fontSize='large'
                color='secondary'
              />
              <Typography variant='h5'>
                Analýza
              </Typography>
              <Typography variant='body2' mt={2}>
                Zpracujeme kompletní analýzu a návrh řešení, včetně technologie a UI.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Box sx={{
              p: 2
            }}
            >
              <ArchitectureOutlinedIcon
                fontSize='large'
                color='secondary'
              />
              <Typography variant='h5'>
                Architektura
              </Typography>
              <Typography variant='body2' mt={2}>
                Navrhneme optimální architekturu, která vás nebude omezovat, ale nebude ani zbytečně drahá.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Box sx={{
              p: 2
            }}
            >
              <CodeOutlinedIcon
                fontSize='large'
                color='secondary'
              />
              <Typography variant='h5'>
                Programování
              </Typography>
              <Typography variant='body2' mt={2}>
                Vytvoříme požadovaný SW přesně podle požadavků a poskytneme možnost dlouhodobého projektu.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Box sx={{
              p: 2
            }}
            >
              <TimelineOutlinedIcon
                fontSize='large'
                color='secondary'
              />
              <Typography variant='h5'>
                Data
              </Typography>
              <Typography variant='body2' mt={2}>
                Datová analýza, velká data nebo machine learning jsou součástí našich projektů. Rádi pomůžeme i vám.
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Box sx={{
              p: 2
            }}
            >
              <RecordVoiceOverOutlinedIcon
                fontSize='large'
                color='secondary'
              />
              <Typography variant='h5'>
                Řízení
              </Typography>
              <Typography variant='body2' mt={2}>
                Odřídíme celý projekt nebo poskytneme konzultace v oblasti agilní dodávky a vedení týmů.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>


  )
}