import React from "react";
import {Container, Divider, Typography} from "@mui/material";

export default function Footer() {
  return (
    <Container sx={{mt: 2}}>
      <Divider/>
      <Typography align='center'>
        Copyright © {new Date().getFullYear()} My Mercatus 24 s.r.o. Všechna práva vyhrazena
      </Typography>
    </Container>

  )
}