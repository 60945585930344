import {Container, Typography} from "@mui/material";
import React from "react";

export default function Contact() {
  return (
    <Container sx={{mt: 8}}>
      <Typography variant='h5' sx={{mb: 2}}>
        Kontaktujte nás
      </Typography>
      <Typography>
        My Mercatus 24 s.r.o.
      </Typography>
      <Typography>
        Rybná 716/24
      </Typography>
      <Typography>
        110 00 Praha 1
      </Typography>
      <Typography>
        777 698 217
      </Typography>
      <Typography>
        info@mymercatus.tech
      </Typography>

      <Typography>
        IČ: 05638658
      </Typography>
      <Typography>
        Společnost je vedená u Městského soudu v Praze oddíl C, číslo vložky 267213
      </Typography>
    </Container>

  )
}