import {Button, Container, Typography} from "@mui/material";
import React from "react";

export default function Headline() {
  return (
    <Container sx={{mt: 8}}>
      <Typography variant='h4' align='center'>
        Technologický inkubátor a dodavatel SW řešení na klíč
      </Typography>
      <Typography variant='subtitle1' align='center' sx={{mt: 4}}>
        Realizace inovativních technologických řešení formou společných projektů nebo dodávkou na klíč
      </Typography>
      {/*<Typography align='center' sx={{mt: 4}}>*/}
      {/*  <Button variant='contained'>Navázat spolupráci</Button>*/}
      {/*</Typography>*/}
    </Container>

  )
}